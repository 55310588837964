@mixin row {
  width: 100%;
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin pie-clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin brAll($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin lessonTitle() {
  margin-top: 0;
  font: {
    size: $lesson-title-size;
    weight: $font-weight-bold;
  }
}

@mixin matSpinnerColor($color) {
  ::ng-deep {
    svg {
      circle {
        stroke: $color;
      }
    }
  }
}
