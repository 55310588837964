@import "../variables/breakpoint";
@import "../variables/colors";
@import "../variables/font";


@mixin theme-base($backGroundColor: $white, $color: $black) {
  &,
  .mat-expansion-panel,
  .mat-sidenav-container,
  .theme-background,
  #library-files-sidebar {
    background-color: $backGroundColor !important;
  }
  &,
  .mat-list-base .mat-list-item,
  .mat-list-item span,
  .mat-expansion-panel-header-title,
  .mat-expansion-panel,
  .mat-toolbar,
  .mat-sidenav-container
  .mat-tab-label,
  .mat-tab-link,
  .mat-drawer-container {
    color: $color;
  }

  a {
    color: $color;
    text-decoration: underline;
  }

  .color-inherit {
    color: inherit;
  }

  .theme-color-icon {
    &.mat-icon {
      svg {
        fill: $backGroundColor;
      }
    }
  }
}
