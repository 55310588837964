/* You can add global styles to this file, and also import other style files */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

@import '~bootstrap/scss/bootstrap.scss';
@import "assets/scss/custom-material-theme.min.css";
@import "assets/scss/variables/colors.scss";
@import "assets/scss/variables/box-shadow.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'add-to-calendar-button/assets/css/atcb.css';
@import '~ckeditor5/dist/ckeditor5.css';

// regular style toast
@import "~ngx-toastr/toastr.css";
@import '~video.js/dist/video-js.min.css';
@import '~videojs-wavesurfer/dist/css/videojs.wavesurfer.min.css';
@import '~videojs-record/dist/css/videojs.record.min.css';
@import '~normalize.css/normalize.css';
@import "assets/scss/toastr.scss";
@import "assets/scss/pagination.scss";
@import "assets/scss/add-to-calendar-button.scss";
@import "assets/scss/one-trust.scss";

@import "assets/scss/themes/dark.scss";
@import "assets/scss/themes/light.scss";
@import "assets/scss/text";
@import "assets/scss/tooltip";
@import "assets/scss/table";
@import "assets/scss/inputs";
@import "assets/scss/form-message";
@import "assets/scss/ng-select";
@import "assets/scss/ngx-charts";
@import "assets/scss/dialog";
@import "assets/scss/client-theme";
@import "assets/scss/cards-grid-layout";
@import "assets/scss/fonts";
@import "assets/scss/variables/font";
@import "assets/scss/variables/spacings";
@import "assets/scss/offline-mode";
@import "assets/scss/base-page";
@import "assets/scss/calendar";
@import "assets/scss/rtl";
@import "assets/scss/native-app";
@import "animate.css";

// shared library styles
@import '../node_modules/library-explorer/assets/scss/styles.scss';

$base-color: #0062D0;
$warm-color: #0059BC;

:root {
  --mobile-min-font: #{$mobileMinFont};
  --mobile-max-font: #{$mobileMaxFont};
  --lms-header-height: 64px;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Material_Icons/material-icons.woff2') format('woff2');
}

html,
body {
  height: 100%;
  margin: 0;
  min-width: auto !important;
  min-height: auto !important;

  font: {
    family: $Poppins !important;
    weight: 300;
    size: 16px;
  }
}

html {
  overflow-y: auto;

  &[dir="rtl"] {

    &,
    body {
      text-align: right;
    }
  }
}

body {
  width: 100%;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba($black, .5);
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease;
    z-index: 999;
  }

  &.no-scroll {
    overflow-y: hidden !important;

    &:before {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.image-download-block {
    &>* {
      img {
        pointer-events: none !important;
      }
    }
  }

  // ngx-dnd question-matching scroll fix
  &.gu-unselectable {
    app-question-matching {
      .right-items {
        .answer-item {
          ngx-dnd-container {
            pointer-events: auto !important;
          }
        }
      }
    }
  }
}

b,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.notch-overlay {
  background-color: $white;
  position: fixed;
  z-index: 999999;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;

  @media (prefers-color-scheme: dark) {
    background-color: $black;
  }
}

.material-icons {
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  font: {
    family: 'Material Icons', sans-serif; 
    weight: normal;
    style: normal;
    size: 24px;
  }
}

.mat-sidenav-container {
  background-color: #FFFFFF !important;
}

.mat-drawer-content {
  height: auto !important;
  min-height: 100%;
  overflow: visible !important;
}

.cdk-overlay-container {
  .mat-dialog-container {
    margin-top: calc(rem-calc(20) + env(safe-area-inset-top));
    height: auto;
  }

  .mat-menu-panel {
    min-width: rem-calc(140);
    border-radius: var(--lms-brand-radius-s);

    .mat-menu-content {
      padding: 0 !important;
    }

    &.base-context-menu {
      padding: $spacing-2 0;
      box-shadow: rem-calc(0 6 10 2) rgba($black, 0.10);

      .base-menu-button {
        display: flex;
        align-items: center;
        gap: $spacing-2;

        font: {
          size: rem-calc(14);
          weight: $font-weight-regular;
        }
      }
    }

    &.user-profile-menu {
      margin-top: rem-calc(12);
      max-width: rem-calc(320);
      overflow: hidden;

      box-shadow: $box-shadow-evaluation-L-2;

      .mat-menu-content {
        padding: rem-calc(8 0) !important;
      }
    }
  }
}



.mat-drawer-content.mat-sidenav-content {
  display: flex;
  flex-direction: column;

  app-content-sitemap {
    flex-grow: 1;
  }
}

.base-button {
  height: 50px;
  width: 142px;
  color: white;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: unset !important;
  display: flex;
  align-content: center;

  &.button-rounded {
    border-radius: rem-calc(6) !important;
  }

  & img {
    width: 5px;
    height: 13px;
  }

  & div {
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.large-button {
  width: 182px;
}

.light-blue-button {
  background-color: $blue12;
}

app-glossary {
  width: 100% !important;
}

header {
  height: 50px;
  min-height: 50px;

  mat-toolbar {
    mat-toolbar-row {
      height: 50px !important;
      min-height: 50px !important;
    }
  }
}

.header-toolbar {
  padding-top: constant(safe-area-inset-top); // for ios 11.1
  padding-top: env(safe-area-inset-top); // for ios 11.2 and onwards
}

.sign-in {
  margin-right: 5px;
  background-color: #FFFFFF;
}

mat-sidenav-container.mat-drawer-container[fullscreen] {
  top: 50px;

  &.mat-drawer-container-has-open {
    overflow-y: hidden !important;
  }
}

mat-nav-list.list-horizontal {
  padding: 0;
  height: 100%;
  display: flex;

  mat-list-item {
    height: 100% !important;
    color: white !important;

    font: {
      weight: 400;
    }
  }
}

mat-nav-list.list-horizontal {
  mat-list-item {
    width: 125px !important;
    justify-content: center;
    display: flex !important;
  }
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

label {
  margin: 0;
  // padding-left: 30px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.mat-slide-toggle {
  .mat-slide-toggle-content {
    font: {
      size: rem-calc(14);
      weight: $font-weight-regular;
    }

    line-height: rem-calc(18);
    letter-spacing: .14px;
  }

  .mat-ripple.mat-slide-toggle-ripple {
    top: calc(50% - #{rem-calc(18)});
    left: calc(50% - #{rem-calc(18)});
    height: rem-calc(32);
    width: rem-calc(32);
    border-radius: 50%;
  }

  .mat-slide-toggle-bar {
    margin-right: rem-calc(12);
    width: rem-calc(32);
    height: rem-calc(12);
    border-radius: rem-calc(100);

    .mat-slide-toggle-thumb-container {
      top: -2px;

      .mat-slide-toggle-thumb {
        height: rem-calc(16);
        width: rem-calc(16);
        background-color: $black;
      }
    }
  }
}

.primary-button:disabled {
  opacity: .5;
  color: $white !important;
}

.touchable {
  cursor: pointer;
}

.mat-list-base .mat-list-item {
  font: {
    size: 14px !important;
  }
}

button:focus {
  outline: none;
}

.small-icon {
  width: 18px !important;
  height: 18px !important;

  font: {
    size: 18px !important;
  }
}

.mat-checkbox {
  .mat-checkbox-frame {
    border-color: $gray100;
  }

  .mat-checkbox-label {
    margin-left: rem-calc(8);
    color: $content-basic-content-high;

    font: {
      size: rem-calc(14);
      weight: $font-weight-regular;
    }
  }
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, .87);
}

.container-max-width {
  max-width: 1450px;
}

@media (max-width: 1660px) {
  .container-max-width {
    max-width: 1140px;
  }
}

.primary-background {
  color: $white !important;
}

.primary-background-lightgray {
  color: $gray11;
}

#zmmtg-root {
  display: none;
  z-index: 2;
}


.cdk-overlay-pane {
  &.certificate-pane {
    .mat-dialog-container {
      padding: 0;
    }
  }

  .mat-select-panel-wrap {
    .mat-option {
      &.mat-active {
        background: rgba($black, .05);
      }
    }
  }
}

.mat-tooltip-panel {
  pointer-events: none;

  .mat-tooltip {
    box-shadow: 0 20px 60px $gray14;
    margin: rem-calc(8 14);
    background-color: $white;
    color: $gray11;
    font-size: rem-calc(12);
    border-radius: var(--lms-brand-radius-xs);

    &.dark-tooltip {
      background-color: $black;
      color: $white;
    }
  }
}

.mat-checkbox {
  &.submitted {
    &.ng-invalid {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-frame {
            border-color: $invalid;
          }
        }
      }
    }
  }
}

.submitted {
  &.ng-invalid {
    &.ng-select {
      .ng-select-container {
        border-color: $invalid;
        border-width: rem-calc(2);
      }
    }

    .ck {
      &.ck-editor {
        .ck-toolbar.ck-toolbar_grouping {
          border-color: $invalid;
          border-width: rem-calc(2);
        }

        .ck-content.ck-editor__editable {
          border-color: $invalid;
          border-width: rem-calc(0 2 2);
        }
      }

    }
  }
}

.image-video-caption {
  background-color: $lightGrey2;
  font-size: 14px;
  font-weight: $font-weight-semibold;
  padding: rem-calc(12 24);

  .caption {
    color: $gray1;
  }

  &.success {
    background-color: $green1;

    .caption {
      color: $white;
    }
  }
}

.certificate-dialog {
  max-width: 90vw !important;
}

.notification-list-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.dialog-no-padding {
  .mat-dialog-container {
    padding: 0;

    @media (min-width: $desktop) {
      min-width: rem-calc(600);
    }
  }
}

.dialog-overflow-visible {
  .mat-dialog-container {
    overflow: visible;
  }
}

.cdk-global-overlay-wrapper {
  transition: justify-content 0.3s ease-out, align-items 0.3s ease 0.5s;
}

.zoom-metting-panel {
  padding: 0;
  width: rem-calc(300);
  height: rem-calc(300);

  &--openned {
    box-shadow: none;
    width: 100vw;
    min-height: calc(100vh - #{rem-calc(178)});

    .mat-dialog-container {
      box-shadow: none;
    }
  }

  &--hidden {
    visibility: hidden;
  }

  .mat-dialog-container {
    width: 100%;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.warning-dialog {
  margin-top: rem-calc(80);

  .mat-dialog-container {
    border-radius: rem-calc(12);
    overflow: visible;
  }
}

.payment-status-dialog,
.offline-activity-dialog {
  .mat-dialog-container {
    padding: 0;
    overflow: visible;
    border-radius: var(--lms-brand-radius-l);
  }
}

.sendbird-chat-panel,
.branch-select-panel,
.language-select-panel {
  max-width: 90vw;

  .mat-dialog-container {
    min-width: 80vw;
    border-radius: var(--lms-brand-radius-l);

    @media (min-width: $desktop) {
      min-width: rem-calc(400);
    }

    padding: 0;

    .mat-dialog-content {
      margin: 0;
      padding: 0;
      max-height: 90vh;
    }
  }
}

.language-select-panel {
  .mat-dialog-container {
    border-radius: var(--lms-brand-radius-l);
  }
}

.sendbird-chat-panel {
  .mat-dialog-container {
    border-radius: var(--lms-brand-radius-l) var(--lms-brand-radius-l) 0 0;

    @media (min-width: $desktop) {
      border-radius: var(--lms-brand-radius-l);
    }
  }

  &.sendbird-chat {
    max-width: 100vw !important;
    transition: .2s ease-in transform;

    &.chat-collapsed {
      transform: translateY(calc(100% - #{rem-calc(64)}));
    }

    .mat-dialog-container {
      border-radius: var(--lms-brand-radius-s) var(--lms-brand-radius-s) 0 0;
      min-width: 90vw;

      @media (min-width: $desktop) {
        border-radius: var(--lms-brand-radius-s) var(--lms-brand-radius-s) 0 0;
        min-width: rem-calc(480);
      }

      .mat-dialog-content {
        overflow: hidden;
      }
    }
  }
}

.chapters-custom-scrollbar {
  .ng-scroll-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ng-scroll-viewport {
    padding-top: rem-calc(16);
  }

  @media (max-width: $breakpoint-medium) {
    &.ng-scrollbar {
      height: unset !important;

      .ng-scroll-viewport,
      .ng-scroll-viewport-wrapper,
      .ng-scrollbar-wrapper {
        overflow: visible !important;
        --vertical-scrollbar-size: 0;
        height: 100%;

        @media (max-width: $breakpoint-medium) {
          max-width: 100%;
        }
      }

      .ng-scroll-viewport {
        contain: inherit;
        will-change: inherit;
      }

      scrollbar-y {
        display: none;
      }
    }
  }

  @media (min-width: $breakpoint-medium) {
    .ng-scroll-content {
      padding-right: rem-calc(12);
    }
  }
}

.custom-scrollbars {
  background-color: $gray17 !important;
}

.disqus-chat-panel {
  @media (max-width: $desktop) {
    margin: rem-calc(40 10) !important;
    width: 95vw !important;
    max-width: unset !important;
  }
}

table:not(.mat-calendar-table) {
  border-collapse: collapse;
  margin: auto;

  &,
  tr,
  th {
    border: rem-calc(1) solid $gray18;
  }
}

blockquote {
  padding-right: rem-calc(16);
  padding-left: rem-calc(16);
  font-style: italic;
  border-left: rem-calc(5) solid $gray5;
}

.preview-file-dialog {
  width: rem-calc(800);
  height: calc(80vh + #{rem-calc(48)});

  .mat-dialog-container {
    overflow: visible;
  }

  @media (max-width: $desktop) {
    width: 95vw !important;
    max-width: unset !important;
  }

  &.image,
  &.video {
    width: auto;
    height: auto;

    .mat-dialog-container {
      background-color: transparent;
      padding: 0;
    }
  }
}

.mat-legal-document-dialog {
  width: rem-calc(1000);
  max-height: calc(80vh + #{rem-calc(48)});
}

.custom-overlay-backdrop {
  background-color: rgba($black, .85);
}

.hidden-certificate-dialog {
  transform: translateX(-500vw);
  padding: 0;
}

.mat-dialog-container {
  position: relative;

  .mat-dialog-close {
    position: absolute;
    right: rem-calc(10);
    top: rem-calc(10);
    -webkit-appearance: none;
  }
}

.mat-error {
  display: block;
  margin-top: $spacing-2;
  line-height: rem-calc(14);
  font-size: rem-calc(14);
  color: $surface-red;
}

.live-update-toast {
  width: fit-content;
  max-width: 90%;
  margin: rem-calc(15) auto;
  padding: rem-calc(15);
  border-radius: var(--lms-brand-radius-m);
  background-color: $white;

  .toast-title {
    color: $black;
  }

  .toast-message {
    color: $gray6;
  }

  .toast-close {
    color: $surface-red;
  }

  .toast-icon {
    color: var(--lms-primary-color);
  }
}

.mat-progress-bar {
  &.default-theme {
    width: rem-calc(84);
    height: rem-calc(8);
    border-radius: var(--lms-brand-radius-s);

    .mat-progress-bar {
      &-fill {
        &:after {
          background-color: $chart-green !important;
        }
      }

      &-buffer {
        background-color: $surface-basic-surface-high !important;
      }
    }
  }
}
