@import "variables/font";
@import "variables/colors";

.glossary-tooltip {
  position: relative;
  display: inline-block;
  text-decoration-style: dashed;
  text-decoration-line: underline;

  span {
    display: inline-block;
  }

  .tooltip-container {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 0;
  }

  .tooltip-text {
    overflow: hidden;
  }

  &.position-top {
    .tooltip-container {
      top: 0;
      transform: translateY(-100%);
    }
  }

  &:hover {
    overflow: visible;
    background: $yellow;

    .tooltip-container {
      overflow: visible;
      box-shadow: 0 rem-calc(4) rem-calc(12) $gray14;
      height: auto;
      visibility: visible;
      background-color: $white;
      color: $black;
      font-size: rem-calc(12);
      border-radius: var(--lms-brand-radius-xs);
      font-weight: $font-weight-regular;
      z-index: 999;
      padding: rem-calc(4);
      width: fit-content;
    }

    .tooltip-text {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 40vh;
      width: rem-calc(160);
      padding: rem-calc(4);

      @media (min-width: $breakpoint-medium) {
        width: max-content;
        min-width: rem-calc(100);
        max-width: rem-calc(240);
        
        max-height: rem-calc(280);
        overflow: scroll;
        overflow-x: hidden;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: rem-calc(4);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: var(--lms-brand-radius-xs);
        background-color: rgba($black, .5);
        -webkit-box-shadow: 0 0 1px rgba($white, .5);
      }
    }
  }
}
