@import "../mixins/theme";
@import "../layout.scss";

.theme-light {
  @include theme-base();

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
      box-shadow: 0 0 0 30px $white inset !important;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-color: currentColor;
  }

  .mat-form-field:not(.gray-form-field) .mat-form-field-outline {
    background-color: $white !important;
  }

  .mat-card {
    box-shadow: rem-calc(0 2 5 0) rgba($black, .15);
    background-color: $white;
    fill: $white;

    &.mat-card-lightblue {
      background-color: $blue13;
      fill: $blue13; 
      box-shadow: none;
    }

    &.completed {
      background-color: $green3;

      &:after {
        border-left-color: darken($green3, 28%);
        border-top-color: darken($green3, 28%);
      }
    }
  }

  .theme-text {
    color: $black;
  }

  .mat-card-achievement {
    @extend .mat-card;
    background-color: $white;
  }

  .user-leaderboard-card {
    @extend .mat-card;
    background-color: $gray50;
  }

  .mat-form-field {
    &.mat-focused {
      .mat-form-field-label {
        color: $gray1;
      }

      .mat-form-field-underline {
        .mat-form-field-ripple {
          background-color: $blackThemeCard;
        }
      }
    }

    .mat-form-field-label {
      color: $gray6;
    }

    .mat-form-field-wrapper {
      .mat-select-value {
        color: $black;
      }

      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: $gray6;
        }
      }
    }
  }

  .mat-select-panel-wrap {
    .mat-primary {
      .mat-option {
        &.mat-selected {
          &:not(.mat-option-disabled) {
            color: $black;
          }
        }
      }
    }
  }

  .dashboard-chat {
    .disqus-container {
      background-color: $blue13;
    }
  }

  app-question-fill-blank {
    .mat-form-field {
      background-color: transparent !important;

      .mat-form-field-flex {
        background: $white;
      }
    }
  }
}
