.mat-calendar {
  &.mat-offline-activity-calendar {
    .mat-calendar-body {
      &-free, &-booked {
        &:after {
          content: '';
          position: absolute;
          width: rem-calc(6);
          height: rem-calc(6);
          border-radius: 50%;
          left: 50%;
          top: 75%;
          transform: translate(-50%, 0%);
        }
      }

      &-free {
        &:after {
          background-color: $greenLight;
        }
      }

      &-booked {
        &:after {
          background-color: $red3;
        }
      }

      &-selected {
        background-color: $white;
        border: 1px solid $blue11;
        color: $black;

        &:before {
          content: 'check';
          font-family: 'Material Icons';
          position: absolute;
          color: $white;
          width: rem-calc(16);
          height: rem-calc(16);
          border-radius: 50%;
          background-color: $blue11;
          left: 100%;
          top: 100%;
          transform: translate(-80%, -80%);
          font-size: rem-calc(8);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid $white;
        }
      }

      &-today {
        background-color: $gray23 !important;
        color: $white;
        border: none;
      }
    }
  }

  &.mat-learners-events-calendar {
    .mat-calendar-content {
      padding: 0;
    }
  
    .mat-calendar-body {
      &-cell {
        &:not(.mat-calendar-body-disabled){
          &:hover {
            .mat-calendar-body-cell-content { 
              &:not(.mat-calendar-body-selected) {
                background-color: var(--lms-primary-color-lighter);
              }
            }
          }
        }
      }

      &-has-events {
        .mat-calendar-body-cell-content {
          &:not(.mat-calendar-body-selected) {
            background-color: var(--lms-primary-color-lighter);
          }
        }
      }

      &-disabled {
        pointer-events: none;
      }

      &-today {
        background-color: inherit;
        color: inherit;
        border: none;
        box-shadow: none;
      }

      &-selected {
        background-color: var(--lms-primary-color);
        color: $white;
        border: none;
        box-shadow: none;
      }
    }
  }

  &.mat-calendar-custom {

    .mat-calendar-controls {
      display: none;
    }

    .mat-calendar-table-header {
      th {
        font-size: rem-calc(16);
        color: $black;
        padding-bottom: rem-calc(16);
        font-weight: $font-weight-bold;
        max-width: rem-calc(48);
        overflow: hidden;
      }

      &-divider {
        display: none;
      }
    }

    .mat-calendar-body {
      &-cell {
        width: rem-calc(40);
        height: rem-calc(40);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &-container {
          padding: rem-calc(24) rem-calc(20) !important;
        }
      }

      &-label {
        padding: 0 !important;
        visibility: hidden;
      }
    }
  }
}
