@import '../layout.scss';
@import './colors.scss';

$box-shadow-evaluation-M-1: rem-calc(0 4 8 0) rgba($black, 0.10);
$box-shadow-evaluation-L-1: rem-calc(0 8 16 0) rgba($black, 0.10);
$box-shadow-evaluation-L-2: rem-calc(0 8 16 2) rgba($black, 0.10);
$box-shadow-evaluation-L-3: rem-calc(0 12 20 4) rgba($black, 0.10);
$box-shadow-evaluation-L-4: rem-calc(0 16 24 8) rgba($black, 0.10);

$box-shadow-evaluation-M-1: rem-calc(0 4 8 0) rgba($black, 0.10);
