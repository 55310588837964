@import "../mixins/theme";

.theme-dark {
  @include theme-base($blackThemeBackground, $white);

  input, input[type=text] {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
      box-shadow: 0 0 0 30px $blackThemeBackground inset !important;
      transition: none !important;
      -webkit-text-fill-color: $white !important;
    }
  }

  ng-select.ng-select.ng-select-default {
  
    &.ng-select-single,
    &.ng-select-multiple {
      .ng-select-container {
        background-color: $blackThemeBackground;
  
        .ng-value-container {
          .ng-value {
            color: $white;
          }
        }
      }
    }
  }

  .mat-form-field:not(.gray-form-field) .mat-form-field-outline {
    background-color: $white !important;
  }

  .mat-checkbox-disabled .mat-checkbox-label {
    color: $white;
    opacity: 0.2;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-color: $white;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $white;
    opacity: 0.8;
  }

  .mat-card {
    background-color: $blackThemeCard;
    fill: $blackThemeCard;
    color: $white;
    box-shadow: none;
    border: none;

    &.completed {
      background-image: linear-gradient(-154deg, $blackThemeCard 0%, $darkGreen 100%);

      &:after {
        border-left-color: $darkGreen;
        border-top-color: $darkGreen;
      }
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $white;
  }

  .sidenav.mat-drawer {
    background-color: $blackThemeBackground;
  }

  .theme-text {
    color: $white;
  }

  .quiz-container {
    .primary-background-lightgray {
      background: $blackThemeCard !important;
    }

    app-question-matching {
      .matching-item-title {
        color: $white;
      }
    }

    app-question-ordering {
      .containers-wrapper {
        .container-item {
          .ngx-dnd-container {
            background-color: $blackThemeBackground !important;
          }

          .order-answer-number {
            color: $white;
          }
        }
      }
    }
  }

  class-item {
    .class-item {
      &.detailed {
        border: none;
      }
    }
  }

  .mat-input-element {
    &::placeholder {
      color: $white;
      opacity: .4;
    }
  }

  .sliding-pane-sidebar.primary-background-lightgray,
  .chat.primary-background-lightgray,
  .chat .disqus.primary-background-lightgray {
    background-color: $blackThemeCard !important;
  }

  .sliding-pane-sidebar-container {
    .sliding-pane-sidebar {
      .item-list {
        li {
          a {
            &:not(.active) {
              color: $gray2 !important;
            }
          }
        }
      }
    }
  }

  .mat-form-field {
    &.mat-focused {
      .mat-form-field-underline {
        .mat-form-field-ripple {
          background-color: $blackThemeCard;
        }
      }
    }

    .mat-form-field-wrapper {
      .mat-select-value {
        color: $white;
      }

      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: $white !important;
        }
      }
    }
  }

  .panel-custom.mat-expansion-panel, .panel-custom.mat-expansion-panel:not(.mat-expanded) .panel-header-custom:hover {
    background-color: $blackThemeCard !important;
  }

  .mat-select-panel-wrap {
    .mat-primary {
      background-color: $blackThemeCard;

      .mat-option {
        color: $white;

        &.mat-selected {
          &:not(.mat-option-disabled) {
            color: $white;
          }
        }
      }
    }
  }

  .dashboard-chat {
    .disqus-container {
      background-color: $blackThemeCard;
    }
  }

  app-dashboard-item-list {
    .item-list {
      background-color: $blackThemeCard;
    }
  }

  app-dashboard-item {
    .item {
      background-color: $blackThemeBackground;

      .content {
        app-progress {
          .progress-container {
            border-top: 1px solid $blackThemeCard !important;
          }
        }
      }
    }
  }

  app-login {
    #login-page {
      background-color: $blackThemeBackground !important;;

      .login-container {
        background-color: $blackThemeBackground !important;;

        .mat-form-field-label {
          mat-label {
            color: $white;
          }
        }

        .mat-form-field {
          &:not(.ng-invalid) {
            .mat-form-field-outline-gap {
              border-bottom-color: $white;
            }
          }
        }

      }
    }
  }

  .fill-blanks__option-item.ngx-dnd-item:not(.primary-background) {
    background-color: $blackThemeBackground !important;
  }

  app-slide-buckets {
    .ngx-dnd-item {
      background-color: $blackThemeBackground;
    }

    .right-bucket .ngx-dnd-container, .left-bucket .ngx-dnd-container, .right-bucket, .left-bucket {
      background-color: $blackThemeBackground !important;
    }
  }

  app-block-list {
    .dark-bg {
      background-color: lighten($blackThemeBackground, 3%) !important;
    }

    .carousel-button {
      color: $white !important;
      border-color: $white !important;
    }

    app-discover-content-block {
      .card-item {
        background-color: $blackThemeBackground !important;
        
        .image-placeholder {
          background-color: $modal-background !important;
        }
      }
    }
  }

  .library-item {
    .icon-wrapper {
      &:hover {
        background-color: $gray6 !important;
      }
      
      mat-icon {
        color: $white !important;
      }
    }
  }

  app-chat-page, app-chat-list {
    .chat {
      &__list {
        background-color: $blackThemeChatBg !important;

        &-header {
          border-color: rgba($grayBorder, .3);
        }

        &-title {
          color: $white
        }

        &-item {
          background-color: $blackThemeChatCard !important;
        }

        .mat-form-field .mat-form-field-flex {
          background-color: $blackThemeChatBg !important;
        }
        &.dialog-mode {
          .ng-scroll-content {
            background: $blackThemeChatCard !important;
          }
        }
      }
    }
  }

  #swipe {
    &.secondary {
      #swipe-list {
        box-shadow: inset 0 -1px 0 $gray19 !important;
      }
    }

    #swipe-list {
      .swipe-item {
        &:hover, &.active-link {
          .title {
            color: $white !important;
          }
        }
      }
    }
  }

  app-chat {
    .chat {
      &__messages {
        background-color: $blackThemeChatCard !important;
      }
      .chat__message:not(.user-message) {
        .chat__message-body {
          background-color: $blackThemeMessageBg !important;
          .triangle {
            border-color: transparent $blackThemeMessageBg transparent transparent;
          }
        }
      }
      &__header {
        background-color: $blackThemeChatHeader !important;
        border: none;
      }

      &__message-input {
        background-color: $blackThemeChatCard !important;
        .mat-form-field .mat-form-field-flex {
          background-color: $blackThemeChatCard !important;
        }
      }
      .chat__fab-btn, .chat__actions-btn {
        border: none !important;
        &:not(.primary-background) {
          background-color: $blackThemeChatBg !important;
          color: $white !important;;
        }

      }
    }
  }

  .sitemap-body {
    .class-wrapper, .course-wrapper, .module-wrapper {
      background-color: $gray19 !important;
      border-color: $gray11 !important;
    }
  }
  .sendbird-chat-panel {
    .chat.dialog-mode {
      background-color: $blackThemeChatHeader;
      .chat__status {
        background-color: $blackThemeChatHeader;
      }
    }
  }

  app-webinar-detail {
    .webinar-details {
      &__tab-group {
        .mat-tab-header {
          background-color: $blackThemeBackground;
          .mat-tab-label-content {
            color: $white;
          }
        }
        .mat-tab-body-content {
          background-color: $blackThemeChatCard;
        }
      }

    }
  }

  app-chapters-page {
    .chapters {
      &__breadcrumbs {
        background-color: $blackThemeChatHeader;
        color: $white;
      }
    }
  }

  app-chat-page {
    .chat {
      &__empty {
        background-color: $blackThemeChatCard;
      }
    }
  }

  app-chat-list-dialog {
    background-color: $blackThemeChatCard;
    display: block;
  }

  app-sidebar-tabs {
    .chat-sidebar__tabs {
      background-color: $blackThemeChatCard !important;
    }
  }

  app-classes, app-lessons {
    background-color: $blackThemeBackground;
  }

  app-classes {
    .classes-list-header {
      .switcher {
        background-color: $darkCard !important;
      }
    }
  }

  app-library-page {
    .library-page {
      background-color: $blackThemeBackground;
    }

    .library-card {
      background-color: $blackThemeChatBg !important;
    }

    .library-item__file-card, .row-layout .library-item__folder-card {
      background-color: $darkCard !important;
    }

    app-list-layout-switcher {
      .layout-switcher {
        background-color: $darkCard;
      }
    }

    .ng-select {
      &-opened {
        & > .ng-select-container {
          background: transparent !important;
        }
      }

      &-container {
        background-color: transparent;
        color: $white;
      }

      .ng-dropdown-panel {
        border-color: $darkCard;
        background-color: transparent !important;
      }

      .ng-option {
        background-color: $blackThemeChatBg;
        color: $white;

        &-selected, &-marked {
          background-color: $darkCard !important;

          .ng-option-label {
            color: $white;
          }
        }
      }
    }

    lib-library-filters {
      .library-filters {
        border-color: $darkCard;
      }
    }

    .empty-folder {
      &__text {
        color: $gray2 !important;
      }
    }
  }

  pagination-controls {
    .ngx-pagination {
      li {
        background-color: $darkCard;

        &.pagination-next, &.pagination-previous {
          &:before {
            border-color: $gray65;
          }

          &:hover {
            &:before {
              border-color: $white;
            }
          }
        }

        a {
          &:hover {
            background-color: darken($darkCard, 5%);
            color: $white;
          }
        }
      }
    }
  }
}
