html.native {
  .cdk-overlay-container {
    .mat-menu-panel {
      &.language-menu {
        max-height: 45vh;
      }
    }
  }

  .content-only-mode {
    .chapters-page-header {
      padding-top: env(safe-area-inset-top) !important;
      height: calc(4rem + env(safe-area-inset-top)) !important;
    }

    .slide-builder-section {
      padding-top: calc(rem-calc(32) + env(safe-area-inset-top)) !important;
    }
  }

}
