@import 'variables/breakpoint.scss';
@import './layout.scss';

.toast-container .ngx-toastr {
  border-radius: var(--lms-brand-radius-m);
  padding: rem-calc(15);
  display: block;
}

.toast-success {
  background-image: unset;
  background-color: $greenLight;
}

.toast-warning {
  background-image: unset;
  background-color: $orange3;
}

.toast-info {
  background-image: unset;
  background-color: $blueDark;
}

.toast-error {
  background-image: unset;
  background-color: $red2;
}

.toast-container.toast-bottom-center .ngx-toastr {
  width: fit-content;
  max-width: 90%;

  @media (min-width: $desktop) {
    max-width: 50%;
  }
}



