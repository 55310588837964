
.base-page {
  display: flex;
  min-height: 100%;
  padding-bottom: rem-calc(60);

  .base-page-container {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
  }

  &-main-content {
    flex-grow: 1;
    width: 100%;
    max-width: rem-calc(1100);
    margin: {
      left: auto;
      right: auto;
    }

    @media (min-width: $breakpoint-xxlarge) {
      max-width: rem-calc(1200);
    }

    @media (min-width: $breakpoint-xxxlarge) {
      max-width: rem-calc(1400);
    }
  }

  &-banner {
    display: flex;
    align-items: center;
    justify-content: center;
  
    border-radius: var(--lms-brand-radius-l);
    position: relative;
    min-height: rem-calc(144);
    padding: rem-calc(24 12);
    background-color: rgba($lightGrey, .2);
    

    @media (min-width: $breakpoint-medium) {
      min-height: rem-calc(240);
      padding: rem-calc(28 48);
    }

    .page-banner-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(to bottom, transparent, rgba($blackThemeCard2, .8) 80%);
      border-radius: inherit;
    }

    .page-banner-image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: inherit;
      object-fit: cover;
    }
    
    .page-banner-title {
      z-index: 9;
      position: relative;
      text-align: center;
      color: $white;
      font-size: rem-calc(32);
      font-weight: $font-weight-semibold;
      margin: 0;

      @media (min-width: $breakpoint-medium) {
        font-size: rem-calc(56);
      }
    }

    .page-banner-search-input {
      position: absolute;
      bottom: 0;
      z-index: 9;
      transform: translateY(50%);
      width: rem-calc(600);
      max-width: 90%;
      box-shadow: rem-calc(0 4 8 0) rgba($black, 0.10);
      border-radius: var(--lms-brand-radius-horizontal);

      &.quick-search {
        transform: translateY(calc(100% - #{rem-calc(28)}));
      }

      .input {
        padding-left: rem-calc(60);
        border-radius: var(--lms-brand-radius-horizontal);
        width: 100%;
        border: none;
      }
    }
  }
}
