@import 'variables/colors.scss';
@import 'variables/spacings.scss';

pagination-controls {
  .ngx-pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    gap: $spacing-1;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: rem-calc(44);
      height: rem-calc(44);
      font-size: rem-calc(14);
      border-radius: var(--lms-brand-radius-horizontal);
      color: $content-basic-content-low;
      padding: 0;

      &.current {
        padding: rem-calc(4);
      }

      &.small-screen {
        font-size: rem-calc(12);
        min-width: rem-calc(50);
        line-height: rem-calc(38);
        text-align: center;
      }

      &.pagination {
        &-previous, &-next {
          position: relative;
          pointer-events: none;

          &.disabled {
            opacity: .2;
            display: none;
          }

          &:before {
            border: solid $black;
            border-width: rem-calc(0 1 1 0);
            padding: rem-calc(4);
            position: absolute;
            left: 50%;
            top: 50%;
          }

          span {
            display: none;
          }

          a {
            font-size: 0;
            pointer-events: all;
          }

          &, a {
            &:before, &:after {
              content: '' !important;
              font-size: 1rem;
              pointer-events: none;
            }
          }
        }

        &-previous {
          &:before {
            transform: translateX(-50%) translateY(-50%) rotate(135deg);
            margin: {
              left: rem-calc(2);
              right: 0 !important;
              top: rem-calc(-1);
            }
          }
        }

        &-next {
          &:before {
            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
            margin: {
              left: rem-calc(-2) !important;
              top: rem-calc(-1);
            }
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        border-radius: var(--lms-brand-radius-horizontal);
        color: $content-basic-content-low;
        padding: rem-calc(4);
        line-height: rem-calc(14);

        &:hover {
          background-color: $gray50;
        }
      }
    }
  }
}