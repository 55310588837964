
ng-select.ng-select {  
  &.ng-select-default {
    margin-top: rem-calc(8);

    &.ng-select-single, &.ng-select-multiple {
      &.ng-select-default {
        &--rounded {
          &, .ng-select-container {
            border-radius: var(--lms-brand-radius-m);
          }
  
          .ng-select-container {
            height: rem-calc(56);
          }
        }
      }

      &.ng-invalid.ng-touched {
        .ng-select-container {
          border-radius: var(--lms-brand-radius-s);
          border: 1px solid $red2;
  
          .ng-placeholder {
            color: $red2;
          }


        }
      }
  
      .ng-arrow-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        top: rem-calc(6);
        width: rem-calc(36);
        padding: rem-calc(0 12 0 8);
  
        .ng-arrow {
          border: none;
          background-image: url('/assets/icons/select-arrow.svg');
          width: rem-calc(16);
          background-repeat: no-repeat;
          height: rem-calc(16);
        }
      }
  
      .ng-select-container {
        height: rem-calc(56);
        min-height: rem-calc(56);
        border-radius: var(--lms-brand-radius-s);
        border: 1px solid $grayBorder50;
  
        .ng-value-container {
          padding-left: rem-calc(20);
          font-size: rem-calc(16);
  
          .ng-placeholder {
            position: static;
            color: $gray100;
            font-weight: $font-weight-semibold;
          }
  
          .ng-input {
            padding-left: rem-calc(20);
            top: rem-calc(16);
            font-size: rem-calc(16);
            height: 0;
          }
        }
      }
    }
  
    &.ng-select-opened {
      .ng-select-container {
        .ng-arrow {
          top: rem-calc(-12);
          left: rem-calc(-4);
          transform: rotate(180deg);
        }
      }
    }
  }

  &.ng-select-primary {

    &.ng-select-single, &.ng-select-multiple {
      &.ng-select-paddings-small {
        .ng-arrow-wrapper {
          padding: rem-calc(0);
        }

        .ng-select-container {
          gap: rem-calc(4);
          padding: rem-calc(8 12 8 16);
        }
      }

      .ng-arrow-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        top: rem-calc(2);
        width: rem-calc(20);
        padding: rem-calc(0 0 0 8);
  
        .ng-arrow {
          border: none;
          background-image: url('/assets/icons/select-arrow-black.svg');
          width: rem-calc(16);
          background-repeat: no-repeat;
          height: rem-calc(8);
        }
      }

      .ng-clear-wrapper {
        width: rem-calc(12);
        display: flex;
        align-items: center;
        justify-content: center;

        .ng-clear {
          font-size: rem-calc(16);
          color: $content-basic-content-high;
        }
      }
  
      .ng-select-container {
        display: flex;
        align-items: center;
        gap: rem-calc(12);
      
        cursor: pointer;
        height: rem-calc(36);
        padding: rem-calc(8 16);
        border-radius: var(--lms-brand-radius-horizontal);
        border: none;
        background: $surface-basic-surface-low; 
        color: $content-basic-content-high;
        box-shadow: none;

        &.ng-has-value {
          .ng-arrow-wrapper {
            display: none;
          }
        }
  
        .ng-value-container {
          padding: rem-calc(0);
          font-size: rem-calc(14);
          color: inherit;

          height: rem-calc(16);
          line-height: rem-calc(16);
          min-height: rem-calc(16);
          align-items: baseline;

          .ng-value {
            font-weight: $font-weight-regular;
          }
  
          .ng-placeholder {
            position: static;
            color: inherit;
            font-weight: $font-weight-regular;
          }
  
          .ng-input {
            padding-left: rem-calc(20);
            top: rem-calc(18);
            font-size: rem-calc(16);
            height: 0;
          }
        }
      }

      .ng-dropdown-panel {
        box-shadow: $box-shadow-evaluation-L-2;
      }

      &.ng-select-non-clearable {
        .ng-select-container {
          .ng-arrow-wrapper {
            display: flex;
          }
        }
      }
    }
  
    &.ng-select-opened {
      .ng-select-container {
        background: $surface-basic-surface-high;
        .ng-arrow {
          transform-origin: center;
          transform: rotate(180deg);
          left: rem-calc(-2);
          top: rem-calc(-4);
        }
      }
    }


    &.ng-select-single {
      .ng-select-container {
        &.ng-has-value {
          background: var(--lms-primary-color-lighter);
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        padding: rem-calc(4 8);
      
        &.ng-has-value {
          .ng-value-container {
            padding: 0;
            height: 100%;
          }
          
          background: inherit;
          border: 2px solid var(--lms-primary-color-lighter);
        }
      }
    }
  }

  &.ng-select-black {
    &.ng-select-single, &.ng-select-multiple {
      .ng-select-container {
        height: rem-calc(44);
        min-height: rem-calc(44);
        border-radius: var(--lms-brand-radius-s);
        border: 1px solid $content-basic-content-lower;
  
        .ng-value-container {
          padding-left: rem-calc(16);
          font-size: rem-calc(14);
  
          .ng-placeholder {
            position: static;
            color: $content-basic-content-low;
            font-weight: $font-weight-semibold;
          }
  
          .ng-input {
            padding-left: rem-calc(16);
            top: rem-calc(10);
            font-size: rem-calc(14);
            height: 0;
          }

          .ng-value {
            font-weight: $font-weight-regular;
          }
        }
      }
    }

    &.ng-invalid.ng-touched {
      .ng-select-container {
        border-radius: var(--lms-brand-radius-s);
        border: 1px solid $surface-red;

        .ng-placeholder {
          color: $surface-red;
        }
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        .ng-arrow {
          transform-origin: center;
          transform: rotate(180deg);
        }
      }
    }

    .ng-arrow-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      top: rem-calc(3);
      width: rem-calc(36);
      padding: rem-calc(0 12 0 8);

      .ng-arrow {
        border: none;
        width: rem-calc(16);
        height: rem-calc(8);
        background: {
          image: url('/assets/icons/select-arrow-black.svg');
          repeat: no-repeat;
          position: center;
        }
      }
    }
  }

  .ng-clear-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray100;
  }

  &.ng-select-primary {
    .ng-dropdown-panel {
      min-width: rem-calc(260);
    }
  }

  &.ng-select-advanced-dialog {
    .ng-dropdown-panel {
      min-width: rem-calc(260);

      .ng-dropdown-panel-items {
        .ng-option {
          padding: rem-calc(12 16);
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          display: none;
        }
  
        .ng-value {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          border-radius: var(--lms-brand-radius-m);
          background: var(--lms-primary-color-lighter);
          padding: rem-calc(4 8);
          color: $content-basic-content-high;
          height: rem-calc(24);
  
          .ng-value-icon {
            &.left {
              border: none;
              background-color: transparent;
              color: $content-basic-content-high;
              font-size: rem-calc(18);
              font-weight: $font-weight-regular;
              line-height: 1;
              padding: rem-calc(0 0 0 6);
            }
          }
        }
      }

      .ng-clear-wrapper {  
        .ng-clear {
          color: $content-basic-content-high;
        }
      }
      
      .ng-more-options {
        color: $content-basic-content-high;
        margin: rem-calc(4 8 8);

        font: {
          size: rem-calc(14);
          weight: $font-weight-regular;      
        };
      }

      &.ng-has-value {
        .ng-value-container {
          padding: rem-calc(6 8 0);
        }
      }
    }
  }

  .ng-dropdown-panel {
    @extend .custom-dropdown-panel;
  }
}

.ng-dropdown-panel {
  &.ng-select-default, &.ng-select-primary, &.ng-select-advanced-dialog, &.ng-select-black {
    @extend .custom-dropdown-panel;
  }

  &.ng-select-advanced-dialog {
    .ng-dropdown-panel-items {
      .ng-option {
        padding: rem-calc(12 16) !important;
      }
    }
  }

  .ng-dropdown-panel-items {
    .ng-option {
      padding: rem-calc(8 12);
    }
  }
}

.custom-dropdown-panel {
  &.ng-select-multiple {
    .ng-dropdown-panel-items {
      .ng-option {
        &.ng-option-marked, &.ng-option-selected {
          background-color: $white;
          color: $black;
        }
      }
    }
  }

  border: none;
  background: $white;
  border-radius: var(--lms-brand-radius-m);  
  box-shadow: rem-calc(0 8 34 0) rgba($black, .1);
  overflow: hidden;

  .ng-option-checkbox {
    user-select: none;
    pointer-events: none;

    .mat-checkbox-label {
      white-space: pre-line;
      line-height: rem-calc(18);
      color: $black;
      font-weight: $font-weight-regular;
      margin-left: rem-calc(4);
    }
  }

  &.ng-select-bottom {
    margin-top: rem-calc(10);
  }

  .ng-dropdown-header {
    border: none;
    padding: rem-calc(12 16);
  }

  .ng-dropdown-footer {
    border: none;
    padding: rem-calc(0 16 12);
  }

  .ng-footer-button {
    border-radius: var(--lms-brand-radius-horizontal);
    font-size: rem-calc(14);
    height: rem-calc(44);
    background-color: var(--lms-primary-color);
    color: $white;
    width: 100%;
  }

  .ng-dropdown-panel-items {
    padding: rem-calc(8 0);
    .ng-option {
      color: $black;
      font-size: rem-calc(14);
      white-space: break-spaces;
      min-height: rem-calc(44);
      padding: rem-calc(8 16); 

      display: flex;
      align-items: center;

      &.ng-option-disabled {
        color: $gray100;
        display: flex;
        justify-content: center;
        font-size: rem-calc(14);
        user-select: none;
        padding: rem-calc(24 0 32);
      }

      &.ng-option-marked, &.ng-option-selected {
        background-color: rgba($surface-basic-surface-high, .6);

        .ng-option-label {
          font-weight: $font-weight-regular;
        }
      }
    }
  }
}
