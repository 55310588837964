@import 'variables/breakpoint.scss';
@import 'variables/spacings.scss';
@import './layout.scss';

.cards-base-grid {
  gap: $spacing-4;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(#{rem-calc(280)}, 1fr));

  &.row-view {
    grid-template-columns: 1fr;
  }
}

