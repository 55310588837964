ngx-charts-chart {
  .axis.y {
    g[ngx-charts-y-axis-ticks] {
      & > g:not(:first-child) {
        display: none;
      }
    }
  }

  .gridline-path {
    &.gridline-path-vertical {
      stroke-dasharray: 3;
      stroke: $darkColor;
      stroke-opacity: .2;
    }
  }

  .line-chart {
    .line-series {
      .line {
        stroke-width: rem-calc(2);
      }
    }
  }

  .tick {
    text {
      fill: $content-basic-content-lower;
    }
  }
}