.form-message {
  color: $darkColor;
  border-radius: var(--lms-brand-radius-xs);
  padding: $spacing-3 $spacing-4 $spacing-3 $spacing-2;
  margin-bottom: rem-calc(16);
  text-align: left;
  display: flex;
  gap: $spacing-2;
  align-items: flex-start;
  line-height: rem-calc(18);
  font: {
    size: rem-calc(14);
    weight: $font-weight-regular;
  }

  .mat-icon {
    color: $white;
    padding: rem-calc(8);
    width: rem-calc(26);
    height: rem-calc(26);
    border-radius: 50%;
    margin-right: rem-calc(8);
    margin-right: rem-calc(12);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem-calc(14);
  }

  lib-icon {
    width: rem-calc(24);
    height: rem-calc(18);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &.error {
    color: $surface-red;
    background-color: $surface-red-low;
  }

  &.warning {
    color: $orange3;
    background-color: $orangeLight;
  }

  &.success {
    color: $surface-green;
    background-color: $surface-green-low;
  }
}
