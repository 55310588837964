html[dir='rtl'] {
  input, textarea, lib-slider-widget, app-chat-dialog, .lesson-slides, app-audio-player .audio-controls,
  app-branch-select-dialog, lib-flipcard-audio {
    direction: rtl;
  }

  .mat-progress-bar {
    backface-visibility: visible;
  }

  .mat-calendar {
    .mat-calendar-header {
      .mat-calendar-controls {
        .mat-calendar-previous-button, .mat-calendar-next-button {
          transform: none;
        }
      }
    }
  }

  .mat-form-field {
    &-outline {
      &-start {
        width: rem-calc(16) !important;
      }
    }

    &-appearance-outline {
      &.datepicker {
        .mat-form-field-suffix {
          right: rem-calc(18) !important;
        }
      }
    }
  }

  .ng-select {
    .ng-arrow-wrapper {
      padding: rem-calc(0 8 0 12) !important;
    }
  }

  .llab-link, .platform-version {
    right: auto;
    left: rem-calc(32);
  }

  .ph-arrow-left, .ph-arrow-right, .ph-caret-right, .ph-caret-left, app-breadcrumb .item .mat-icon,
  .mat-slider-track-wrapper, app-audio-player .play-button .mat-icon,
  .ngx-pagination .pagination-previous, .pagination-next,
  app-promotion-block .carousel__button,
  app-learners-events-calendar .events-calendar-header .events-calendar-navigation app-icon-button,
  app-branch-list-item .select-button .mat-icon,
  lib-flipcard-audio .audio-play-button .mat-icon {
    transform: scale(-1, 1);
  }

  #cc--main {
    .b-tg {
      direction: ltr;
    }

    #s-c-bnc {
      width: rem-calc(60);
    }

    #s-c-bn {
      float: left;
    }

    #s-all-bn {
      margin-left: rem-calc(8);
    }

    #s-rall-bn {
      margin-left: 0;
    }
  }

  app-swiper {
    .swiper-buttons-wrapper {
      transform: translateY(-50%) scale(-1, 1);
    }
  }

  .block .carousel-buttons-wrapper {
    flex-direction: row-reverse;
    transform: scale(-1, 1);
  }

  app-carousel-block {
    .carousel-block {
      .carousel-block__button {
        &.carousel-button--right {
          right: auto;
          left: rem-calc(20);
          transform: translateY(-50%) scale(-1, 1);
        }

        &.carousel-button--left {
          transform: translateY(-50%);
        }
      }
    }
  }

  app-learning-path-card {
    .learning-path {
      .learning-path-next {
        right: auto !important;
        left: rem-calc(24);
      }
    }
  }

  app-learning-path-timeline-intro, app-learning-path-timeline-item {
    .learning-path__timeline-item {
      &:before {
        left: auto;
        right: rem-calc(37);
      }
    }
  }

  lib-library-details-sidebar {
    .library-details {
      &:not(.opened) {
        .library-sidebar {
          transform: translateX(-100%) !important;
        }
      }

      .library-sidebar {
        @media (min-width: $tablet-large) {
          left: 0 !important;
          right: auto !important;
        }

        .library-details-value {
          direction: rtl;
        }
      }
    }
  }

  .next-question-icon {
    &::after {
      transform: scale(-1, 1);
    }
  }

  app-sidebar-tabs {
    .sidebar {
      &.hide-navigation {
        .chat-sidebar {
          &.collapsed {
            transform: translateX(-100%) !important;
          }
        }
      }

      .chat-sidebar {
        right: auto;
        left: 0;
  
        &.collapsed {
          transform: translateX(calc(-100% + #{rem-calc(72)}));

          @media (max-width: $desktop) {
            transform: none;
          }
        }
  
        &__close {
          right: auto !important;
          left: rem-calc(10);
        }
      }
    }
  }

  .navigation-sidebar-menu {
    border-radius: var(--lms-brand-radius-l) 0 0 var(--lms-brand-radius-l);

    &.hidden {
      transform: translateX(100%);
    }
  }

  app-chat-message-input {
    .chat-message-input {
      .message-input {
        .input {
          padding: rem-calc(0 0 0 136) !important;
        }
      }
    }
  }

  .ng-dropdown-panel {
    left: auto !important;
  }

  app-profile-main {
    .profile-main__link-active-indicator {
      left: auto;
      right: rem-calc(-12);
    }
  }

  app-legal-center {
    .legal-center__status {

      @media (min-width: $breakpoint-medium) {
        flex-direction: row;
        margin: {
          right: rem-calc(100);
          left: 0;
        };
      }

      .left-icon {
        margin: {
          left: auto;
          right: -10vw;
        }

        @media (min-width: $breakpoint-medium) {
          margin: {
            left: auto !important;
            right: rem-calc(-100) !important;
          }
        }
      }
    }
  }

  app-audio-player {
    .audio-controls {
      .audio-volume {
        right: auto !important;
        left: 0;
      }
    }
  }

  app-branch-list-item {
    .actions {
      .select-button {
        margin: {
          left: 0 !important;
          right: rem-calc(30);
        }

        &:before {
          left: auto !important;
          right: rem-calc(-30);
        }
      }
    }
  }
}
