@import 'variables/colors.scss';

.table {
  td {
    border-right: 1px solid $gray18;
  }
}

// ckeditor content
figure {
  &.table {
    td {
      padding: rem-calc(6);
      vertical-align: middle;
    }

    th {
      padding: rem-calc(6);
      vertical-align: middle;
    }
  }
}