@import './layout.scss';
@import './variables/spacings.scss';
@import './variables/colors.scss';
@import './variables/font.scss';


.offline-mode {
  &-indicator {
    margin: rem-calc(0 12);

    padding: $spacing-1 $spacing-2;
    font: {
      size: rem-calc(12);
      weight: $font-weight-regular;
    }

    border-radius: var(--lms-brand-radius-xs);
    background-color: $surface-basic-surface-low-2;
  }

  &-disabled {
    pointer-events: none;
    opacity: .4;
  }
}
