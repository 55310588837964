#atcb-bgoverlay {
  cursor: default !important;
}

#atcb-pos-wrapper {
  .atcb-list-wrapper.atcb-dropdown {
    .atcb-list {
      border-radius: var(--lms-brand-radius-m);
      box-shadow: $box-shadow-evaluation-L-1;

      &-item {
        border: none;
        background-color: $white;

        &:hover {
          background-color: $gray3;
        }

        .atcb-icon {
          margin-top: rem-calc(-8);
        }
      }
    }
  }
}